<template>
  <p class="clearfix mb-0">
    <!-- <span class="float-md-right d-none d-md-block">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>

      
      <b-link
        class="ml-25"
        href="#"
        target="_blank"
      >Op Plus</b-link>
      
    </span> -->

    
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
