export default [
  {
    title: 'لوحة التحكم',
    route: '/',
    icon: 'UserIcon',
  },
  {
    title: 'المدراء',
    route: 'admin',
    icon: 'UserIcon',
  },
  {
    title: 'العملاء',
    route: 'customer',
    icon: 'UserIcon',
  },
  {
    title: 'مزودي الخدمات',
    route: 'provider',
    icon: 'UserIcon',
  },
  {
    title: 'ملف مزودي الخدمات',
    route: 'providerProfile',
    icon: 'UserIcon',
  },
  
  {
    title: 'الخدمات',
    route: 'service',
    icon: 'UserIcon',
  },
  {
    title: 'اﻷسعار',
    route: 'price',
    icon: 'UserIcon',
  },
  {
    title: 'الفترات الزمنية',
    route: 'timePeriod',
    icon: 'UserIcon',
  },
  {
    title: 'السياسات',
    route: 'policy',
    icon: 'UserIcon',
  },
  {
    title: 'اﻹشعارات',
    route: 'firbase',
    icon: 'UserIcon',
  },
  
  {
    title: 'البنرات اﻹعلانية',
    route: 'banner',
    icon: 'UserIcon',
  },
  {
    title: 'الطلبات',
    route: 'orders',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'الطلبات غير النشطة',
    route: 'ordersInActive',
    icon: 'ShoppingCartIcon',
  },
]
